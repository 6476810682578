import { Suspense, lazy, useState } from "react";
// import Navbar from "./component/Navbar/Navbar";
// import Section1 from "./component/Sections/Section1";
// import { Footer } from "./component/Common/Footer/Footer";
// import { Footer } from "./component/Common/Footer/Footer";
// import { Section10 } from "./component/Sections/Section10";
// import { Section11 } from "./component/Sections/Section11";
// import { Section12 } from "./component/Sections/Section12";
// import { Section2 } from "./component/Sections/Section2";
// import { Section3 } from "./component/Sections/Section3";
// import { Section4 } from "./component/Sections/Section4";
// import { Section5 } from "./component/Sections/Section5";
// import { Section6 } from "./component/Sections/Section6";
// import { Section7 } from "./component/Sections/Section7";
// import { Section8 } from "./component/Sections/Section8";
// import { Section9 } from "./component/Sections/Section9";
// import { TEST } from "./component/Sections/TEST";
// import { lazy } from "react";

const Navbar = lazy(() => import("./component/Navbar/Navbar"));
const Section1 = lazy(() => import("./component/Sections/Section1"));
const Footer = lazy(() => import("./component/Common/Footer/Footer"));
const Section2 = lazy(() => import("./component/Sections/Section2"));
const Section3 = lazy(() => import("./component/Sections/Section3"));
const Section4 = lazy(() => import("./component/Sections/Section4"));
const Section5 = lazy(() => import("./component/Sections/Section5"));
const Section6 = lazy(() => import("./component/Sections/Section6"));
const Section7 = lazy(() => import("./component/Sections/Section7"));
const Section8 = lazy(() => import("./component/Sections/Section8"));
const Section9 = lazy(() => import("./component/Sections/Section9"));
const Section10 = lazy(() => import("./component/Sections/Section10"));
const Section11 = lazy(() => import("./component/Sections/Section11"));
const Section12 = lazy(() => import("./component/Sections/Section12"));

function App() {
  const [videoask_modal, setvideoask_modal] = useState(false);
  return (
    <>
      <Suspense fallback={<div>.</div>}>
        <Navbar {...{ setvideoask_modal }} />
        <Section1 />
        <Section2 {...{ setvideoask_modal }} />
        <Section3 />
        <Section4 />
        <Section5 />
        <Section6 {...{ setvideoask_modal }} />
        <Section7 />
        <Section8 />
        <Section9 />
        <Section10 />
        <Section11 />
        <Section12 {...{ setvideoask_modal }} />
        <Footer />
      </Suspense>
      {videoask_modal && (
        <>
          <style>
            [class*="videoask-embed__button"]
            {`{--opacity:1;--disp_prop:none}`}
          </style>
          <div
            className="videoask_modal"
            id="videoask_modal"
            onClick={(e) =>
              e.target.id == "videoask_modal" ? setvideoask_modal(false) : ""
            }
          >
            <iframe
              src="https://www.videoask.com/fdcfw1yby"
              allow="camera ; microphone; autoplay ; encrypted-media; fullscreen ; display-capture;"
              style={{ border: `none`, borderRadius: 24 }}
            ></iframe>
          </div>
        </>
      )}
    </>
  );
}

export default App;
